import { IProject, ITableFilter } from "@lib";
import { FilterFactory, IFilter, IFilterContext } from "@/core/alt-forms/filter";

export interface ITaskFilterContext extends IFilterContext {
    filters: IFilter[];
    tfilters: ITableFilter[];
    projects: IProject[];
}

export const createFilters = (): IFilter[] => {
    return [
        FilterFactory.createInFilter<IProject, ITaskFilterContext>(
            "project",
            "Проект",
            context => context.projects,
            s => s.id,
            s => s.info.name,
        ),
    ];
};
