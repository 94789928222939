


















import { Vue, Component, Prop } from "vue-property-decorator";
import { BButton, BDropdown, BDropdownDivider, BDropdownItem, VBTooltip } from "bootstrap-vue";
import { ITableFilter } from "@lib";
import AltAutoButton from "@/core/components/alt-auto-button";

@Component({
    name: "task-toolbar",
    components: {
        BButton,
        BDropdown,
        BDropdownDivider,
        BDropdownItem,
        AltAutoButton,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class TaskToolbar extends Vue {
    @Prop({ type: Array, default: () => [] })
    private filter!: ITableFilter[];

    @Prop({ type: Boolean, default: false })
    private canCreate!: boolean;

    private get hasFilter(): boolean {
        return this.filter.length > 0 && !!this.filter.find(f => f.field !== "project");
    }

    private async showModalCreate(): Promise<void> {
        this.$emit("show-create");
    }

    private async showModalFilter(): Promise<void> {
        this.$emit("show-filter");
    }
}
