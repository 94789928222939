import { IModule, IProjectModule } from "@lib";
import { Control, Html } from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";
import { Formatter } from "@/utils/formatter";
import { AppException } from "@/core/exceptions";
import { CheckListModal, ICheckListModalContext } from "./check-list.modal";
import { IModuleContext, IModuleProvider } from "..";

export class CheckListModuleProvider implements IModuleProvider {
    private readonly _modal = new CheckListModal();

    private _context: IModuleContext | null = null;

    public get module(): IModule | null {
        return this._context?.module ?? null;
    }

    public get pmodule(): IProjectModule | null {
        return this._context?.pmodule ?? null;
    }

    public get context(): IModuleContext | null {
        return this._context ?? null;
    }

    public set context(value: IModuleContext | null) {
        this._context = value;
    }

    public getModal(): Modal<any, any> {
        return this._modal;
    }

    public showModal(data: any): Promise<boolean> {
        if (!this._context) {
            throw new AppException("Context is not specified.");
        }

        const context: ICheckListModalContext = {
            moduleContext: this._context,
            data: data,
        };

        return this._modal.show(context);
    }

    public createDataView(data: any): Control {
        let text = "";

        if (data.begin) {
            text += `Начало: ${Formatter.datetime(data.begin)}`;
        }

        if (data.end) {
            if (text.length > 0) {
                text += "<br/>";
            }

            text += `Срок: ${Formatter.datetime(data.end)}`;
        }

        const html = new Html();
        html.html = text;
        return html;
    }
}
