import {
    ICompany,
    IProject,
    IProjectModuleUpdateDto,
    IReadQuery,
    ISelectedData,
    ISettingsTableUpdateDto,
    ITable,
    ITableColumn,
    ITableFilter,
    ITableSort,
    ITask,
    ITaskCreateDto,
    ITaskDeleteManyDto,
    ITaskStage,
    IUser,
    TableType,
} from "@lib";
import { TableApi } from "@/core/components/alt-table";
import { AltPlugin, InfoPlugin, SettingsPlugin } from "@/utils/plugins";
import { AppException } from "@/core/exceptions";

/** Данные таблицы. */
export type TableData = {
    api: TableApi;
    type: TableType;
    page: number;
    search: string;
    settings: ITable | null;
};

/** Загрузчик данных для раздела Задачи. */
export class ProjectsDataLoader {
    private _company: ICompany | null = null;
    private _user: IUser | null = null;
    private _projects: IProject[] | null = null;

    private get company(): ICompany {
        if (!this._company) {
            throw new AppException("Загрузчик данных не был проинициализирован.");
        }

        return this._company;
    }

    private get user(): IUser {
        if (!this._user) {
            throw new AppException("Загрузчик данных не был проинициализирован.");
        }

        return this._user;
    }

    private get projects(): IProject[] {
        if (!this._projects) {
            throw new AppException("Загрузчик данных не был проинициализирован.");
        }

        return this._projects;
    }

    public constructor(
        private readonly $alt: AltPlugin,
        private readonly $info: InfoPlugin,
        private readonly $settings: SettingsPlugin,
    ) {}

    public async init(): Promise<void> {
        this._company = await this.$info.getCompany();
        this._user = await this.$info.getUser();
        this._projects = await this.$info.getProjects();
    }

    /** Загрузить проекты, доступные пользователю. */
    public async selectProjects(): Promise<IProject[]> {
        try {
            const projects = await this.$alt.system.usecase
                .createEmployeeUseCase()
                .selectProjects(this.company.id, this.user.id);
            this.$info.setProjects(projects);
            return projects;
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить проекты:\n${e.message}`);
        }
    }

    /** Загрузить настройки таблицы. */
    public async selectTableSettings(type: TableType): Promise<ITable | null> {
        try {
            // TODO: если настроек нет, то возвращается null.
            // Если ошибка - бросается исключение.
            return await this.$alt.system.usecase.createSettingsTableUseCase().get(this.company.id, this.user.id, type);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - колонки. */
    public async updateTableColumns(type: TableType, columns: ITableColumn[]): Promise<ITable | null> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - сортировку. */
    public async updateTableSort(type: TableType, sort: ITableSort[]): Promise<ITable | null> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - количество элементов. */
    public async updateTableLimit(type: TableType, limit: number): Promise<ITable | null> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch {
            return null;
        }
    }

    /** Обновить настройки таблицы - фильтр. */
    public async updateTableFilter(type: TableType, filter: ITableFilter[]): Promise<ITable> {
        try {
            const dto: ISettingsTableUpdateDto = { filter };
            return await this.$alt.system.usecase
                .createSettingsTableUseCase()
                .update(this.company.id, this.user.id, type, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось сохранить настройки фильтра:\n${e.message}`);
        }
    }

    /** Загрузить этапы задач. */
    public async selectTaskStages(): Promise<ITaskStage[]> {
        try {
            return await this.$alt.system.usecase.createTaskStageUseCase().select(this.company.id);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить этапы:\n${e.message}`);
        }
    }

    /** Загрузить задачи. */
    public async selectTasksForTable(table: TableData): Promise<ISelectedData<ITask>> {
        try {
            const limit = table.settings?.limit ?? TableApi.DefaultLimit;
            const offset = (table.page - 1) * limit;
            const tsort = table.settings?.sort;
            const tfilter = table.settings?.filter;
            const search = table.search;

            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const query: IReadQuery = { limit, offset, sort, search, filter };
            return await this.$alt.system.usecase.createTaskUseCase().select(this.company.id, query);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить задачи:\n${e.message}`);
        }
    }

    /** Загрузить задачи. */
    public async selectTasks(
        limit: number,
        offset: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
        search?: string,
    ): Promise<ISelectedData<ITask>> {
        try {
            const filter = this.$alt.system.query.tableFilterToQueryFilter(tfilter);
            const sort = this.$alt.system.query.tableSortToQuerySort(tsort);
            const query: IReadQuery = { limit, offset, sort, search, filter };
            return await this.$alt.system.usecase.createTaskUseCase().select(this.company.id, query);
        } catch (e: any) {
            throw new Error(`Не удалось загрузить задачи:\n${e.message}`);
        }
    }

    /** Загрузить данные задачи. */
    public async getTask(id: string): Promise<ITask> {
        try {
            return await this.$alt.system.usecase.createTaskUseCase().get(this.company.id, id);
        } catch (e: any) {
            throw new AppException(`Не удалось загрузить данные задачи:\n${e.message}`);
        }
    }

    // /** Поиск задач. */
    // public async searchTasks(search: string, projectId: string): Promise<ITask[]> {
    //     try {
    //         const query: ISelectQuery = {
    //             search,
    //             limit: 10,
    //         };
    //         const result = await this.$alt.system.usecase
    //             .createTaskUseCase()
    //             .selectForProject(this.company.id, projectId, query);

    //         return result.data;
    //     } catch {
    //         return [];
    //     }
    // }

    /** Создать задачу. */
    public async createTask(dto: ITaskCreateDto): Promise<void> {
        try {
            await this.$alt.system.usecase.createTaskUseCase().create(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось создать задачу:\n${e.message}`);
        }
    }

    // /** Изменить задачу. */
    // public async updateTask(task: ITask, dto: ITaskUpdateDto): Promise<void> {
    //     try {
    //         await this.$alt.system.usecase.createTaskUseCase().update(this.company.id, task.id, dto);
    //     } catch (e: any) {
    //         throw new AppException(`Не удалось изменить задачу:\n${e.message}`);
    //     }
    // }

    /** Удалить задачу. */
    public async deleteTask(task: ITask): Promise<void> {
        try {
            await this.$alt.system.usecase.createTaskUseCase().delete(this.company.id, task.id, {});
        } catch (e: any) {
            throw new AppException(`Не удалось удалить задачу:\n${e.message}`);
        }
    }

    /** Удалить несколько задач. */
    public async deleteTasks(tasks: ITask[]): Promise<void> {
        try {
            const dto: ITaskDeleteManyDto = {
                ids: tasks.map(g => g.id),
            };

            await this.$alt.system.usecase.createTaskUseCase().deleteMany(this.company.id, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось удалить задачи:\n${e.message}`);
        }
    }

    /** Сохранить настройки модуля в проекте. */
    public async saveProjectModuleSettings(projectId: string, pmoduleId: string, settings: any): Promise<void> {
        try {
            const dto: IProjectModuleUpdateDto = { settings };
            await this.$alt.system.usecase
                .createProjectModuleUseCase()
                .update(this.company.id, projectId, pmoduleId, dto);
        } catch (e: any) {
            throw new AppException(`Не удалось сохранить настройки модуля:\n${e.message}`);
        }
    }
}
