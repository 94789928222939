import { Uuid } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Button, ColorBox, Control, Panel, TextBox } from "@core/components/alt-ui/controls";

export interface ITagsModuleTag {
    id: string;
    color: string;
    text?: string;
}

export interface ITagsTagModalResult {
    answer: "ok" | "cancel" | "remove";
    tag?: ITagsModuleTag;
}

export class TagsTagModal extends Modal<ITagsModuleTag, ITagsTagModalResult> {
    private clColor!: ColorBox;
    private tbText!: TextBox;

    private pnlFooter!: Panel;
    private btnRemove!: Button;
    private btnCancel!: Button;
    private btnSave!: Button;

    private tag?: ITagsModuleTag;

    public constructor() {
        super("module-tags-tag", "");
        this.initializeControls();
    }

    public show(tag?: ITagsModuleTag): Promise<ITagsTagModalResult> {
        this.tag = tag;
        this.title = tag ? "Изменение метки" : "Создание метки";
        this.initializeControls();

        if (tag) {
            this.populateControls(tag);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.clColor = new ColorBox();
        this.clColor.id = "module-tags-tag.color";
        this.clColor.value = "#00ff00";

        this.tbText = new TextBox();
        this.tbText.id = "module-tags-tag.text";

        this.btnRemove = new Button();
        this.btnRemove.id = "module-tags-tag.remove";
        this.btnRemove.text = "Удалить";
        this.btnRemove.class = "mr-0.75";
        this.btnRemove.variant = "danger";
        this.btnRemove.addClickHandler(() => this.hide({ answer: "remove" }));
        this.btnRemove.visible = false;

        this.btnCancel = new Button();
        this.btnCancel.id = "module-tags-tag.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(() => this.hide({ answer: "cancel" }));

        this.btnSave = new Button();
        this.btnSave.id = "module-tags-tag.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnRemove);
        //this.pnlFooter.addControl(this.btnCancel);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(tag: ITagsModuleTag): void {
        this.clColor.value = tag.color;
        this.tbText.text = tag.text ?? "";
        this.btnRemove.visible = true;
    }

    public get controls(): Control[] {
        return [this.clColor, this.tbText, this.pnlFooter];
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        const tag: ITagsModuleTag = {
            id: this.tag?.id ?? Uuid.new(),
            color: this.clColor.value,
            text: this.tbText.text.trim().length > 0 ? this.tbText.text : undefined,
        };

        this.hide({ answer: "ok", tag });
    }
}
