import { IModule, IProjectModule } from "@lib";
import { Badge, Control, Panel } from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";
import { AppException } from "@/core/exceptions";
import { ITagsModalContext, TagsModal } from "./tags.modal";
import { IModuleContext, IModuleProvider } from "..";

export class TagsModuleProvider implements IModuleProvider {
    private readonly _modal = new TagsModal();

    private _context: IModuleContext | null = null;

    public get module(): IModule | null {
        return this._context?.module ?? null;
    }

    public get pmodule(): IProjectModule | null {
        return this._context?.pmodule ?? null;
    }

    public get context(): IModuleContext | null {
        return this._context ?? null;
    }

    public set context(value: IModuleContext | null) {
        this._context = value;
    }

    public getModal(): Modal<any, any> {
        return this._modal;
    }

    public showModal(data: any): Promise<boolean> {
        if (!this._context) {
            throw new AppException("Context is not specified.");
        }

        const context: ITagsModalContext = {
            moduleContext: this._context,
            data: data,
        };

        return this._modal.show(context);
    }

    public createDataView(data: any): Control {
        const pnl = new Panel();
        pnl.class = "flex";

        if (!this._context?.pmodule?.settings?.tags || !data.tags) {
            return pnl;
        }

        const tags = this._context.pmodule.settings.tags.filter((t: any) => data.tags.includes(t.id));

        for (const tag of tags) {
            const badge = new Badge();
            badge.class = "w-10 mr-0.25 cursor-pointer";
            badge.color = tag.color;
            badge.text = tag.text ?? "";
            badge.addClickHandler(() => {
                this.showModal(data);
            });

            pnl.addControl(badge);
        }

        return pnl;
    }
}
