import { Vue, Component, Watch } from "vue-property-decorator";
import {
    ICompany,
    IEmployee,
    IProject,
    IProjectModule,
    ITableColumn,
    ITableFilter,
    ITableSort,
    ITask,
    ITaskStage,
    IUser,
    PermissionCommonSection,
    PermissionRight,
    PermissionType,
    TableType,
} from "@lib";
import AltTable, { TableApi } from "@/core/components/alt-table";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { Modal2Component } from "@core/components/alt-ui/modal-2";
import { Button, Icon } from "@core/components/alt-ui/controls";
import { MultiDropdown } from "@core/controls/multi-dropdown/multi-dropdown";
import { AltButton } from "@/core/alt-ui";
import { FilterInfo, FilterModal } from "@/core/alt-forms/filter";
import { getDefaultTableColumns, getDefaultTableActions } from "./task-defaults-table";
import TaskToolbar from "./task-toolbar.vue";
import { TaskModal, ITaskModalContext } from "./modals/task.modal";
import { ITaskFilterContext, createFilters } from "./task-filter";
import { ProjectsDataLoader, TableData } from "../projects-data-loader";

@Component({
    name: "tasks",
    components: { AltButton, TaskToolbar, AltTable, ModalComponent, Modal2Component },
})
export default class Tasks extends Vue {
    private DataLoader = new ProjectsDataLoader(this.$alt, this.$info, this.$settings);

    private featureEnabled = false;
    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private employees: IEmployee[] = [];
    private projects: IProject[] = [];
    private selectedProjects: IProject[] = [];
    private companyTaskCount: number = 0;
    private taskStages: ITaskStage[] = [];

    private tasks: ITask[] = [];
    private tasksTotal: number = 0;

    private table: TableData = {
        api: new TableApi(),
        type: TableType.Task,
        page: 1,
        search: "",
        settings: null,
    };

    private filterInfo = new FilterInfo<ITaskFilterContext>();

    private taskModal = new TaskModal();
    private taskFilterModal = new FilterModal<ITaskFilterContext>("task-filter");

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get defaulActions(): any[] {
        return getDefaultTableActions(this);
    }

    private get can(): any {
        const secure = this.$secure;
        const projects = this.selectedProjects;
        return {
            get create(): boolean {
                for (const project of projects) {
                    const valid = secure.check(PermissionType.Projects, project.id, PermissionRight.TasksCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
            get update(): Function {
                return (task: ITask): boolean => {
                    return secure.check(PermissionType.Projects, task.project, PermissionRight.TasksUpdate);
                };
            },
            get delete(): Function {
                return (task: ITask): boolean => {
                    return secure.check(PermissionType.Projects, task.project, PermissionRight.TasksDelete);
                };
            },
            get createProject(): boolean {
                return secure.checkCommon(PermissionCommonSection.Projects, PermissionRight.Create);
            },
        };
    }

    private get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings?.columns ?? []);
    }

    private get filter(): ITableFilter[] {
        return this.table.settings?.filter ?? [];
    }

    @Watch("selectedProjects", { deep: true, immediate: true })
    private onSelectedProjectsUpdate(): void {
        const headerControls = this.$info.ui.getHeaderControls();

        const hdrProjects = headerControls.find(c => c.id === "projects.header-projects") as MultiDropdown<IProject>;
        if (hdrProjects) {
            if (!this.$alt.compareArrays(hdrProjects.selectedItems, this.selectedProjects, e => e.id)) {
                hdrProjects.selectedItems = this.selectedProjects;
            }
        }
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.employees = await this.$info.getEmployees();
            this.projects = await this.$info.getProjects();
            this.companyTaskCount = await this.$info.getTaskCount();
            this.featureEnabled = this.company.features?.projects?.enabled ?? false;
            if (this.featureEnabled) {
                await this.DataLoader.init();
                await this.initData();
                await this.initParams();
            }
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        [this.table.settings, this.taskStages] = await Promise.all([
            this.DataLoader.selectTableSettings(this.table.type),
            this.DataLoader.selectTaskStages(),
        ]);

        const filterProjects = this.filter.filter(item => item.field === "project");
        this.selectedProjects = this.projects.filter(s => !!filterProjects.find(f => f.value === s.id));

        this.filterInfo.init({
            filters: createFilters(),
            tfilters: this.filter,
            projects: this.projects,
        });

        if (this.selectedProjects.length === 0 && this.projects[0]) {
            this.filterInfo.setValue("project", [this.projects[0].id]);
            await this.changeTableFilter(this.filterInfo);
        }

        this.initHeader();

        await this.selectData();
    }

    private initHeader(): void {
        const hdrProjects = new MultiDropdown<IProject>();
        hdrProjects.id = "projects.header-projects";
        hdrProjects.items = this.projects;
        hdrProjects.selectedItems = this.selectedProjects;
        hdrProjects.itemId = item => item.id;
        hdrProjects.itemName = item => item.info.name;
        hdrProjects.iconPackage = "feather";
        hdrProjects.icon = "TrelloIcon";
        hdrProjects.locale = {
            Tooltip: "Выбрать проект",
            ButtonSelectOne: "Выбрать один",
            ButtonSelectMultiple: "Выбрать несколько",
            ButtonSelectAll: "Выбрать все",
            TextNotSelected: "Проект не выбран",
            TextSelectedAll: "Все проекты",
            TextSelectedMultiple: "Выбрано:",
            TextSelectedMultipleForms: ["проект", "проекта", "проектов"],
        };
        hdrProjects.addChangedHandler((s, e) => {
            const ids = e.items.map(project => project.id);

            this.filterInfo.setValue("project", ids);
            this.changeTableFilterAndRefresh(this.filterInfo);
        });

        const hdrRefresh = new Button();
        hdrRefresh.id = "projects.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => {
            this.$alt.longOperation(() => this.initData(), "Данные обновлены.");
        });

        this.$info.ui.setHeaderControls([hdrProjects, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$alt.queryExits("new")) {
                this.showModalCreate();
                return;
            }

            const taskId = this.$alt.queryGetFirst("id");
            if (taskId) {
                const task = await this.DataLoader.getTask(taskId);
                this.showModalUpdate(task);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw e;
        }
    }

    public async changeTableColumns(columns: ITableColumn[]): Promise<void> {
        this.$alt.longOperation(async () => {
            const settings = await this.DataLoader.updateTableColumns(this.table.type, columns);

            if (!settings) {
                this.table.settings = settings;
            }
        });
    }

    public async changeTableSort(sort: ITableSort[]): Promise<void> {
        this.$alt.longOperation(async () => {
            const settings = await this.DataLoader.updateTableSort(this.table.type, sort);

            if (!settings) {
                this.table.settings = settings;
                await this.selectData();
            }
        });
    }

    public async changeTableLimit(limit: number, page: number): Promise<void> {
        this.$alt.longOperation(async () => {
            const settings = await this.DataLoader.updateTableLimit(this.table.type, limit);

            if (!settings) {
                this.table.settings = settings;
                this.table.page = page;
                await this.selectData();
            }
        });
    }

    public async changeTablePage(page: number): Promise<void> {
        this.$alt.longOperation(async () => {
            this.table.page = page;
            await this.selectData();

            // TODO: добавить к запросу номер страницы: p=2
            // if (page === 1) super.queryRemove("p");
            // else super.queryAdd("p", page);
        });
    }

    private async changeTableFilter(filterInfo: FilterInfo<ITaskFilterContext>): Promise<boolean> {
        try {
            this.filterInfo = filterInfo;
            const filter = this.filterInfo.getTableFilter();
            this.table.settings = await this.DataLoader.updateTableFilter(this.table.type, filter);

            const filterProjects = this.filter.filter(item => item.field === "project");
            this.selectedProjects = this.projects.filter(s => !!filterProjects.find(f => f.value === s.id));
            return true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return false;
        }
    }

    private async changeTableFilterAndRefresh(filterInfo: FilterInfo<ITaskFilterContext>): Promise<void> {
        this.$alt.longOperation(async () => {
            const success = await this.changeTableFilter(filterInfo);

            if (success) {
                await this.selectData();
            }
        });
    }

    public async searchData(search: string): Promise<void> {
        try {
            // начинать поиск от 2 символов
            this.table.search = search.trim().length > 1 ? search : "";
            await this.selectData();
        } catch (e: any) {
            this.tasks = [];
            this.tasksTotal = 0;
        }
    }

    public async showModalCreate(): Promise<void> {
        const context: ITaskModalContext = {
            projects: this.projects,
            projectSelected: this.selectedProjects.length > 0 ? this.selectedProjects[0] : undefined,
            stages: this.taskStages,
            createHandler: dto =>
                this.$alt.longOperation(() => this.DataLoader.createTask(dto), "Задача успешно создана."),
            saveProjectModuleSettingsHandler: (project: IProject, pmodule: IProjectModule, settings: any) =>
                this.$alt.longOperation(() =>
                    this.DataLoader.saveProjectModuleSettings(project.id, pmodule.id, settings),
                ),
        };

        const success = await this.taskModal.show(context);

        if (success) {
            this.companyTaskCount += 1;
            this.$info.setTaskCount(this.companyTaskCount);
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public async showModalUpdate(task: ITask): Promise<void> {
        // const context: ITaskModalContext = {
        //     projects: this.projects,
        //     projectSelected: this.selectedProjects.length > 0 ? this.selectedProjects[0] : undefined,
        //     stages: this.taskStages,
        //     task: task,
        //     // updateHandler: (task, dto) =>
        //     //     this.$alt.longOperation(() => this.DataLoader.updateTask(task, dto), "Задача успешно изменена."),
        // };
        // const success = await this.taskModal.show(context);
        // if (success) {
        //     await this.$alt.longOperation(() => this.selectData());
        // }
    }

    public async showModalFilter(): Promise<void> {
        const success = await this.taskFilterModal.show({
            filters: createFilters(),
            tfilters: this.filter,
            projects: this.projects,
            saveHandler: info => this.$alt.longOperationBool(() => this.changeTableFilter(info)),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData());
        }
    }

    public async confirmDelete(task: ITask): Promise<void> {
        const answer = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить задачу: "${task.info.name}"?`,
            "Удаление задачи",
            { acceptText: "Удалить" },
        );

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.DataLoader.deleteTask(task);
                this.$alt.toast.success("Задача успешно удалена.");
                await this.selectData();
            });
        }
    }

    public async confirmDeleteMany(tasks: ITask[]): Promise<void> {
        const answer = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить ${tasks.length} задач?`,
            "Удаление задач",
            { acceptText: "Удалить" },
        );

        if (answer) {
            await this.$alt.longOperation(async () => {
                await this.DataLoader.deleteTasks(tasks);
                this.$alt.toast.success("Задачи успешно удалены.");
                await this.selectData();
            });
        }
    }

    private async selectData(): Promise<void> {
        try {
            const result = await this.DataLoader.selectTasksForTable(this.table);
            this.tasks = result.data;
            this.tasksTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить задачи:\n${e.message}`);
        }
    }
}
