import { ITask, ITaskStage, IProject, ITaskCreateDto, IProjectModule } from "@lib";
import {
    Button,
    Control,
    Icon,
    IconPackage,
    Label,
    Panel,
    Select,
    TextArea,
    TextBox,
} from "@core/components/alt-ui/controls";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Modal } from "@core/components/alt-ui/modal";
import { Footer } from "@core/controls/footer.control";
import { AppException } from "@/core/exceptions";
import { IModuleContext, IModuleProvider, ModuleProviderFactory } from "../modules";

export interface ITaskModalContext {
    projects: IProject[];
    projectSelected?: IProject;
    stages: ITaskStage[];
    task?: ITask;
    createHandler?: (dto: ITaskCreateDto) => Promise<boolean>;
    saveProjectModuleSettingsHandler?: (project: IProject, pmodule: IProjectModule, settings: any) => Promise<boolean>;
}

export class TaskModal extends Modal2<ITaskModalContext, boolean> {
    private pnlMain!: Panel;
    private pnlLeft!: Panel;
    private pnlRight!: Panel;

    private cbProject!: Select<IProject>;
    private cbStage!: Select<ITaskStage>;
    private tbName!: TextBox;
    private taDescription!: TextArea;
    private pnlModules!: Panel;

    private btnSave!: Button;
    private btnCancel!: Button;

    private ftFooter!: Footer;

    private context?: ITaskModalContext;
    private moduleProviders: IModuleProvider[] = [];
    private moduleModals: Modal<any, any>[] = [];
    private modules: any[] = [];

    public constructor() {
        super("task-modal", "");
        this.initializeControls();
    }

    public show(context: ITaskModalContext): Promise<boolean> {
        this.context = context;
        this.title = "Новая задача";

        this.initializeModuleProviders();
        this.initializeControls();

        if (context.task) {
            this.title = `Задача № ${context.task.number}`;
            this.populateControls(context.task);
        }

        return super.show();
    }

    private initializeModuleProviders(): void {
        this.moduleProviders = [];
        this.moduleModals = [];

        const project = this.context?.projectSelected;

        if (!project?.modules) {
            return;
        }

        for (const pmodule of project.modules) {
            if (pmodule.disabled || !pmodule.moduleRef) {
                continue;
            }

            const provider = ModuleProviderFactory.createByCode(pmodule.moduleRef.code);

            if (!provider) {
                continue;
            }

            const name = pmodule.moduleRef.name;
            const icon = pmodule.moduleRef.icon?.value ?? "plugin";

            const moduleContext: IModuleContext = {
                module: pmodule.moduleRef,
                pmodule: pmodule,
                project: project,
                saveHandler: async (data: any) => {
                    const moduleIndex = this.modules.findIndex(m => m.pmodule.id === pmodule.id);
                    const moduleItem = this.modules[moduleIndex];

                    if (moduleIndex >= 0) {
                        // edit
                        const content = provider.createDataView(data);
                        const control = this.createModalBlock(icon, name, content);
                        this.pnlModules.controls.splice(moduleIndex, 1, control);

                        moduleItem.data = data;
                        moduleItem.control = control;
                    } else {
                        // create
                        const content = provider.createDataView(data);
                        const control = this.createModalBlock(icon, name, content);
                        this.pnlModules.addControl(control);

                        this.modules.push({
                            pmodule,
                            data,
                            control,
                        });
                    }

                    return true;
                },
                removeHandler: async () => {
                    const moduleIndex = this.modules.findIndex(m => m.pmodule.id === pmodule.id);
                    const moduleItem = this.modules[moduleIndex];

                    if (moduleIndex >= 0) {
                        // remove
                        this.pnlModules.controls.splice(moduleIndex, 1);
                        this.modules.splice(moduleIndex, 1);
                    }
                    return true;
                },
                saveSettingsHandler: async (settings: any) => {
                    if (!this.context?.saveProjectModuleSettingsHandler) {
                        return false;
                    }

                    return await this.context.saveProjectModuleSettingsHandler(project, pmodule, settings);
                },
            };

            provider.context = moduleContext;

            this.moduleProviders.push(provider);
            this.moduleModals.push(provider.getModal());
        }
    }

    private initializeControls(): void {
        this.initializeLeftPanel();
        this.initializeRightPanel();

        this.pnlMain = new Panel();
        this.pnlMain.class = "w-100 flex";
        this.pnlMain.addControls([this.pnlLeft, this.pnlRight]);

        this.ftFooter = new Footer({
            //okText: this.context?.task ? "Изменить" : "Создать",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private initializeLeftPanel(): void {
        this.cbProject = new Select<IProject>();
        this.cbProject.id = "task.project";
        this.cbProject.items = this.context?.projects ?? [];
        this.cbProject.textField = p => p.info.name;
        this.cbProject.descriptionField = p => p.info.description;
        this.cbProject.selectedItem =
            this.cbProject.items.find(p => p.id === this.context?.projectSelected?.id) ??
            (this.cbProject.items.length > 0 ? this.cbProject.items[0] : null);

        const pnlProject = this.createModalBlock("TrelloIcon", "Проект", this.cbProject);

        this.cbStage = new Select<ITaskStage>();
        this.cbStage.id = "task.stage";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = s => s.name;
        this.cbStage.descriptionField = s => s.description;
        this.cbStage.selectedItem = this.cbStage.items.length > 0 ? this.cbStage.items[0] : null;

        const pnlStage = this.createModalBlock("CheckCircleIcon", "Этап", this.cbStage);

        this.tbName = new TextBox();
        this.tbName.id = "task.name";
        this.tbName.class = "mb-0.5";

        const pnlName = this.createModalBlock("HashIcon", "Название", this.tbName);

        this.taDescription = new TextArea();
        this.taDescription.id = "task.description";
        this.taDescription.class = "mb-0.5";

        const pnlDescription = this.createModalBlock("list", "Описание", this.taDescription);

        this.pnlModules = new Panel();

        //

        this.pnlLeft = new Panel();
        this.pnlLeft.class = "w-75%";
        this.pnlLeft.addControls([pnlProject, pnlStage, pnlName, pnlDescription, this.pnlModules]);
    }

    private initializeRightPanel(): void {
        this.btnSave = new Button();
        this.btnSave.id = "task.tools.save";
        this.btnSave.class = "w-100 mb-0.5 mt-1";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler((s, e) => this.clickSave());

        this.btnCancel = new Button();
        this.btnCancel.id = "task.tools.cancel";
        this.btnCancel.class = "w-100 mb-1";
        this.btnCancel.text = "Отмена";
        this.btnCancel.variant = "outline-secondary";
        this.btnCancel.addClickHandler((s, e) => this.clickCancel());

        this.pnlRight = new Panel();
        this.pnlRight.class = "ml-1.5 block";
        this.pnlRight.addControls([this.btnSave, this.btnCancel]);

        //

        const pnlRightTools = new Panel();

        if (this.moduleProviders.length > 0) {
            const lbl = new Label();
            lbl.class = "uppercase font-semibold w-100 mt-2 mb-0.5";
            lbl.text = "Добавить";

            pnlRightTools.addControl(lbl);

            for (const provider of this.moduleProviders) {
                const btn = new Button();
                btn.class = "w-100 flex items-center justify-start mb-0.5";
                btn.icon = new Icon();
                btn.icon.package = IconPackage.Alt;
                btn.icon.icon = provider.module?.icon?.value ?? "plugin";
                btn.text = provider.module?.name ?? "";
                btn.variant = "outline-secondary";
                btn.addClickHandler(() => this.clickModule(provider));

                pnlRightTools.addControl(btn);
            }
        }

        this.pnlRight.addControl(pnlRightTools);

        //

        const pnlRightActions = new Panel();

        const lbl = new Label();
        lbl.class = "uppercase font-semibold w-100 mt-2 mb-0.5";
        lbl.text = "Действия";

        pnlRightTools.addControl(lbl);

        const btnMove = new Button();
        btnMove.class = "w-100 flex items-center justify-start mb-0.5";
        btnMove.icon = new Icon();
        btnMove.icon.icon = "ArrowRightIcon";
        btnMove.text = "Переместить";
        btnMove.variant = "outline-secondary";

        const btnCopy = new Button();
        btnCopy.class = "w-100 flex items-center justify-start mb-0.5";
        btnCopy.icon = new Icon();
        btnCopy.icon.icon = "CopyIcon";
        btnCopy.text = "Скопировать";
        btnCopy.variant = "outline-secondary";

        const btnDelete = new Button();
        btnDelete.class = "w-100 flex items-center justify-start mb-0.5";
        btnDelete.icon = new Icon();
        btnDelete.icon.icon = "Trash2Icon";
        btnDelete.text = "Удалить";
        btnDelete.variant = "outline-secondary";

        pnlRightTools.addControls([btnMove, btnCopy, btnDelete]);

        this.pnlRight.addControl(pnlRightActions);
    }

    private populateControls(task: ITask): void {
        this.cbProject.selectedItem = this.cbProject.items.find(p => p.id === task.project) ?? null;
        this.cbProject.disabled = true;

        this.cbStage.selectedItem = this.cbStage.items.find(s => s.id === task.stage) ?? null;
        this.cbStage.disabled = true;

        this.tbName.text = task.info.name ?? "";
        this.taDescription.text = task.info.description ?? "";
    }

    private createModalBlock(icon: string, title: string, content: Control): Panel {
        const icIcon = new Icon();
        icIcon.class = "w-1.25 h-1.25 mt-0.125";
        icIcon.package = IconPackage.Alt;
        icIcon.icon = icon;

        const pnlLeft = new Panel();
        pnlLeft.class = "mr-0.75";
        pnlLeft.addControl(icIcon);

        const lbTitle = new Label();
        lbTitle.class = "text-xl font-semibold mb-0.5";
        lbTitle.text = title;

        const pnlContent = new Panel();
        pnlContent.class = "w-100";
        pnlContent.addControls([lbTitle, content]);

        const pnlModule = new Panel();
        pnlModule.class = "flex mb-2";
        pnlModule.addControls([pnlLeft, pnlContent]);
        return pnlModule;
    }

    // public get footer(): Footer {
    //     return this.ftFooter;
    // }

    public get controls(): Control[] {
        return [this.pnlMain];
    }

    public get submodals(): Modal<any, any>[] {
        return this.moduleModals;
    }

    private async clickModule(provider: IModuleProvider): Promise<void> {
        if (!provider.pmodule) {
            throw new AppException("Project module is not set");
        }

        const pmodule = provider.pmodule;
        const moduleIndex = this.modules.findIndex(m => m.pmodule.id === pmodule.id);
        const moduleItem = this.modules[moduleIndex];

        await provider.showModal(moduleItem?.data);
    }

    private async clickCancel(): Promise<void> {
        this.hide(false);
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        if (!this.context?.createHandler || !this.cbProject.selectedItem || !this.cbStage.selectedItem) {
            return;
        }

        const dto: ITaskCreateDto = {
            project: this.cbProject.selectedItem.id,
            info: {
                name: this.tbName.text,
                description: this.taDescription.text,
            },
            stage: this.cbStage.selectedItem.id,
            //employees: [],
        };

        const ok = await this.context.createHandler(dto);

        if (ok) {
            this.hide(true);
        }
    }
}
