import { Modal } from "@core/components/alt-ui/modal";
import { Button, Control, Panel, TextBox } from "@core/components/alt-ui/controls";
import { IModuleContext } from "..";

export interface ICheckListModalContext {
    moduleContext: IModuleContext;
    data?: any; // TODO: уточнить тип
}

export class CheckListModal extends Modal<ICheckListModalContext, boolean> {
    private tbName!: TextBox;

    private pnlFooter!: Panel;
    private btnRemove!: Button;
    private btnSave!: Button;

    private context?: ICheckListModalContext;

    public constructor() {
        super("module-check-list", "Чек-лист");
        this.initializeControls();
    }

    public show(context: ICheckListModalContext): Promise<boolean> {
        this.context = context;
        this.initializeControls();

        if (context.data) {
            this.populateControls(context.data);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.tbName = new TextBox();
        this.tbName.id = "module-check-list.name";
        this.tbName.label = "Название";
        this.tbName.validation = "required";

        this.btnRemove = new Button();
        this.btnRemove.id = "module-check-list.remove";
        this.btnRemove.text = "Удалить";
        this.btnRemove.class = "mr-0.75";
        this.btnRemove.variant = "outline-danger";
        this.btnRemove.addClickHandler(() => this.clickRemove());
        this.btnRemove.visible = false;

        this.btnSave = new Button();
        this.btnSave.id = "module-check-list.save";
        this.btnSave.text = this.context?.data ? "Сохранить" : "Добавить";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnRemove);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(data: any): void {
        this.tbName.text = data?.name ?? "";
        this.btnRemove.visible = true;
    }

    public get controls(): Control[] {
        return [this.tbName, this.pnlFooter];
    }

    private async clickRemove(): Promise<void> {
        if (!this.context?.moduleContext.removeHandler) {
            return;
        }

        const ok = await this.context.moduleContext.removeHandler();

        if (ok) {
            this.hide(true);
        }
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        // TODO: проверка, что мин дата меньше макс даты

        if (!this.context?.moduleContext.saveHandler) {
            return;
        }

        const data: any = {
            name: this.tbName.text,
            items: [],
        };

        const ok = await this.context.moduleContext.saveHandler(data);

        if (ok) {
            this.hide(true);
        }
    }
}
