import { ITask } from "@lib";
import { IAltTableAction, IAltTableColumn } from "@/core/components/alt-table";

export function getDefaultTableColumns(context: any): IAltTableColumn<ITask>[] {
    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            minWidth: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            hide: false,
            headerClass: "text-transparent",
            headerCheckboxSelection: true,
            checkboxSelection: true,

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: any) => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (task: ITask) => context.can.update(task),
                            click: (task: ITask) => context.showModalUpdate(task),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (task: ITask) => context.can.delete(task),
                            click: (task: ITask) => context.confirmDelete(task),
                        },
                    ],
                };
            },
        },
        {
            colId: "project",
            field: "info.project",
            headerName: "Проект",
            sortable: true,
            filter: true,
            width: 175,
            hide: true,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: params => {
                return {
                    name: params.data.projectRef?.info.name,
                };
            },
        },
        {
            colId: "name",
            field: "info.name",
            headerName: "Название",
            sortable: true,
            filter: true,
            width: 500,
            hide: false,
        },
        {
            colId: "description",
            field: "info.description",
            headerName: "Описание",
            sortable: true,
            filter: true,
            width: 500,
            hide: true,
        },
        {
            colId: "tags",
            field: "tags",
            headerName: "Метки",
            filter: true,
            width: 150,
            hide: false,

            cellRendererFramework: "CellRendererTags",
            cellRendererParams: params => {
                const tags = params.value;
                if (!tags) {
                    return;
                }

                return {
                    tags,
                    handler: (t: string) => context.table.api.search(t),
                };
            },
        },
    ];
}

export function getDefaultTableActions(context: any): IAltTableAction<ITask>[] {
    const actions: IAltTableAction<ITask>[] = [];

    actions.push({
        name: "Удалить всё",
        icon: "Trash2Icon",
        click: (tasks: ITask[]) => context.confirmDeleteMany(tasks),
    });

    return actions;
}
