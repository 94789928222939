import { IModule, IProject, IProjectModule, ITask } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import { Control } from "@core/components/alt-ui/controls";
import { DatesModuleProvider } from "./dates/dates.module-provider";
import { TagsModuleProvider } from "./tags/tags.module-provider";
import { CheckListModuleProvider } from "./check-list/check-list.module-provider";

export interface IModuleContext {
    /** Модуль. */
    module: IModule;

    /** Модуль в рамках проекта. */
    pmodule: IProjectModule;

    /** Проект, в котором находится задача. */
    project: IProject;

    // /** Задача, в которую добавляют модуль. */
    // task?: ITask;

    // /** Данные модуля из задачи. */
    // data?: any;

    /** Обработчик сохранения данных модуля. */
    saveHandler?: (data: any) => Promise<boolean>;

    /** Обработчик удаление модуля. */
    removeHandler?: () => Promise<boolean>;

    /** Обработчик сохранения настроек модуля. */
    saveSettingsHandler?: (settings: any) => Promise<boolean>;
}

/** Набор методов для работы с модулем. */
export interface IModuleProvider {
    /** Получить модуль. */
    get module(): IModule | null;

    // /** Задать модуль. */
    // set module(value: IModule | null);

    /** Получить модуль в рамках проекта. */
    get pmodule(): IProjectModule | null;

    // /** Задать модуль в рамках проекта. */
    // set pmodule(value: IProjectModule | null);

    /** Получить контекст модуля. */
    get context(): IModuleContext | null;

    /** Задать контекст модуля. */
    set context(value: IModuleContext | null);

    /** Получить экземпляр модалки. */
    getModal(): Modal<any, any>;

    /** Показать модалку. */
    showModal(data: any): Promise<boolean>;

    /** Создать представление данных для модалки задачи. */
    createDataView(data: any): Control;
}

export abstract class ModuleProviderFactory {
    public static createByCode(code: string): IModuleProvider | null {
        switch (code) {
            case "c9bbd814-aa42-4b68-a667-15b02d9121b6":
                return new DatesModuleProvider();
            case "8ec3c9a5-74e2-4524-aff3-c866622f7c3f":
                return new TagsModuleProvider();
            case "710dd93f-dc18-4b6d-8d64-49b7b54f195e":
                return new CheckListModuleProvider();
        }

        return null;
    }
}
