import { Modal } from "@core/components/alt-ui/modal";
import { Badge, Button, CheckBox, Control, Icon, Panel } from "@core/components/alt-ui/controls";
import { ITagsModuleTag, TagsTagModal } from "./tags-tag.modal";
import { IModuleContext } from "..";

export interface ITagsModalContext {
    moduleContext: IModuleContext;
    data?: any; // TODO: уточнить тип
}

export interface ITagsModuleTagEnabled {
    tag: ITagsModuleTag;
    enabled: boolean;
}

export class TagsModal extends Modal<ITagsModalContext, boolean> {
    private pnlTags!: Panel;
    private btnCreate!: Button;

    private pnlFooter!: Panel;
    private btnRemove!: Button;
    private btnSave!: Button;

    private tagModal = new TagsTagModal();

    private context?: ITagsModalContext;
    private tags: ITagsModuleTagEnabled[] = [];

    public constructor() {
        super("module-tags", "Метки");
        this.initializeControls();
    }

    public show(context: ITagsModalContext): Promise<boolean> {
        this.context = context;
        this.tags =
            context.moduleContext.pmodule.settings?.tags?.map((t: ITagsModuleTag) => ({ tag: t, enabled: false })) ??
            [];
        this.initializeControls();

        if (context.data) {
            this.populateControls(context.data);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.pnlTags = new Panel();
        this.initializeTagsPanel();

        this.btnCreate = new Button();
        this.btnCreate.id = "module-tags.create";
        this.btnCreate.text = "Создать метку";
        this.btnCreate.class = "w-100";
        this.btnCreate.variant = "flat-primary";
        this.btnCreate.addClickHandler(async () => {
            const result = await this.tagModal.show();

            if (result?.answer === "ok" && result.tag) {
                this.tags.push({ tag: result.tag, enabled: false });
                this.initializeTagsPanel();
                this.updateModuleSettings();
            }
        });

        this.btnRemove = new Button();
        this.btnRemove.id = "module-tags.remove";
        this.btnRemove.text = "Удалить";
        this.btnRemove.class = "mr-0.75";
        this.btnRemove.variant = "outline-danger";
        this.btnRemove.addClickHandler(() => this.clickRemove());
        this.btnRemove.visible = false;

        this.btnSave = new Button();
        this.btnSave.id = "module-tags.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-1";
        this.pnlFooter.addControl(this.btnRemove);
        this.pnlFooter.addControl(this.btnSave);
    }

    private initializeTagsPanel(): void {
        this.pnlTags.clearControls();

        for (const te of this.tags) {
            const tagPanel = this.createTagPanel(te);
            this.pnlTags.addControl(tagPanel);
        }
    }

    private createTagPanel(te: ITagsModuleTagEnabled): Panel {
        const chk = new CheckBox();
        chk.id = `tag-checkbox-${te.tag.id}`;
        chk.value = te.enabled ?? false;
        chk.addValueChangedHandler((s, e) => {
            te.enabled = e.value;
        });

        const badge = new Badge();
        badge.id = `tag-badge-${te.tag.id}`;
        badge.class = "w-100 py-0.75 mx-0.5 cursor-pointer";
        badge.style = `background-color: ${te.tag.color}`;
        badge.text = te.tag.text ?? "";
        badge.addClickHandler(() => {
            chk.value = !chk.value;
        });

        const btn = new Button();
        btn.id = `tag-edit-${te.tag.id}`;
        btn.class = "p-0.75";
        btn.variant = "flat-secondary";
        btn.icon = new Icon();
        btn.icon.class = "w-1 h-1";
        btn.icon.icon = "Edit2Icon";
        btn.addClickHandler(async () => {
            const result = await this.tagModal.show(te.tag);

            if (result?.answer === "ok" && result.tag) {
                te.tag.color = result.tag.color;
                te.tag.text = result.tag.text;
                this.initializeTagsPanel();
                this.updateModuleSettings();
            } else if (result?.answer === "remove") {
                const index = this.tags.findIndex(t => t.tag.id === te.tag.id);

                if (index >= 0) {
                    this.tags.splice(index, 1);
                }

                this.initializeTagsPanel();
                this.updateModuleSettings();
            }
        });

        const pnl = new Panel();
        pnl.class = "flex items-center w-100 mb-0.25";
        pnl.addControls([chk, badge, btn]);
        return pnl;
    }

    private populateControls(data: any): void {
        for (const te of this.tags) {
            te.enabled = data?.tags.includes(te.tag.id) ?? false;
        }

        this.initializeTagsPanel();

        this.btnRemove.visible = true;
    }

    public get controls(): Control[] {
        return [this.pnlTags, this.btnCreate, this.pnlFooter];
    }

    public get submodals(): Modal<any, any>[] {
        return [this.tagModal];
    }

    private async updateModuleSettings(): Promise<void> {
        if (!this.context?.moduleContext.saveSettingsHandler) {
            return;
        }

        const settings: any = {
            tags: this.tags.map(t => t.tag),
        };

        const ok = await this.context.moduleContext.saveSettingsHandler(settings);

        if (ok) {
            this.context.moduleContext.pmodule.settings = settings;
        }
    }

    private async clickRemove(): Promise<void> {
        if (!this.context?.moduleContext.removeHandler) {
            return;
        }

        const ok = await this.context.moduleContext.removeHandler();

        if (ok) {
            this.hide(true);
        }
    }

    private async clickSave(): Promise<void> {
        const ids = this.tags.filter(t => t.enabled).map(t => t.tag.id);

        if (ids.length === 0) {
            await this.clickRemove();
            return;
        }

        if (!this.context?.moduleContext.saveHandler) {
            return;
        }

        const data: any = {
            tags: ids,
        };

        const ok = await this.context.moduleContext.saveHandler(data);

        if (ok) {
            this.hide(true);
        }
    }
}
