import { Modal } from "@core/components/alt-ui/modal";
import { Button, CheckBox, Control, DateTime, Panel } from "@core/components/alt-ui/controls";
import { IModuleContext } from "..";

export interface IDatesModalContext {
    moduleContext: IModuleContext;
    data?: any; // TODO: уточнить тип
}

export class DatesModal extends Modal<IDatesModalContext, boolean> {
    private dtBegin!: DateTime;
    private dtEnd!: DateTime;
    private chbDone!: CheckBox;

    private pnlFooter!: Panel;
    private btnRemove!: Button;
    private btnSave!: Button;

    private context?: IDatesModalContext;

    public constructor() {
        super("module-dates", "Даты");
        this.initializeControls();
    }

    public show(context: IDatesModalContext): Promise<boolean> {
        this.context = context;
        this.initializeControls();

        if (context.data) {
            this.populateControls(context.data);
        }

        return super.show();
    }

    protected initializeControls(): void {
        this.dtBegin = new DateTime();
        this.dtBegin.id = "module-dates.begin";
        this.dtBegin.label = "Начало выполнения";

        this.dtEnd = new DateTime();
        this.dtEnd.id = "module-dates.end";
        this.dtEnd.label = "Срок выполнения";

        this.chbDone = new CheckBox();
        this.chbDone.id = "module-dates.done";
        this.chbDone.class = "ml-1 mt-1.5";
        this.chbDone.text = "Выполнено";
        this.chbDone.visible = false;

        this.btnRemove = new Button();
        this.btnRemove.id = "module-dates.remove";
        this.btnRemove.text = "Удалить";
        this.btnRemove.class = "mr-0.75";
        this.btnRemove.variant = "outline-danger";
        this.btnRemove.addClickHandler(() => this.clickRemove());
        this.btnRemove.visible = false;

        this.btnSave = new Button();
        this.btnSave.id = "module-dates.save";
        this.btnSave.text = "Сохранить";
        this.btnSave.addClickHandler(() => this.clickSave());

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControl(this.btnRemove);
        this.pnlFooter.addControl(this.btnSave);
    }

    private populateControls(data: any): void {
        this.dtBegin.value = data?.begin ?? null;
        this.dtEnd.value = data?.end ?? null;
        this.chbDone.value = data.done;
        this.chbDone.visible = true;
        this.btnRemove.visible = true;
    }

    public get controls(): Control[] {
        return [this.dtBegin, this.dtEnd, this.chbDone, this.pnlFooter];
    }

    private async clickRemove(): Promise<void> {
        if (!this.context?.moduleContext.removeHandler) {
            return;
        }

        const ok = await this.context.moduleContext.removeHandler();

        if (ok) {
            this.hide(true);
        }
    }

    private async clickSave(): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        // TODO: проверка, что мин дата меньше макс даты

        if (!this.context?.moduleContext.saveHandler) {
            return;
        }

        const data: any = {
            begin: this.dtBegin.value,
            end: this.dtEnd.value,
        };

        if (this.context?.data) {
            data.done = this.chbDone.value;

            if (this.chbDone.value) {
                data.doneAt = new Date();
            }
        }

        const ok = await this.context.moduleContext.saveHandler(data);

        if (ok) {
            this.hide(true);
        }
    }
}
